
import useLikeOrCollect from './useLikeOrCollect'
import useReviews from './useReviews'
import { defineComponent, PropType, toRefs } from 'vue'
import { CourseDetail, Page } from 'momai'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    courseDetail: {
      type: Object as PropType<CourseDetail>,
      required: true
    },
    reviewPageData: {
      type: Object as PropType<Page>,
      required: true
    }
  },
  setup: (props) => {
    const { courseId } = useRoute().params
    const { courseDetail } = toRefs(props)
    return {
      ...useReviews({
        courseDetail,
        courseId,
        reviewPageData: props.reviewPageData
      }),
      ...useLikeOrCollect({
        courseDetail,
        courseId
      })
    }
  }
})
