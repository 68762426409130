import { api } from '@/api/useAxios'
import { Ref } from 'vue'
import { CourseDetail } from 'momai'
import { likeOrUnlike } from '@/api/likeOrUnlike'
import { Toast } from 'vant'

export default ({
  courseDetail,
  courseId
}: {
  courseDetail: Ref<CourseDetail>;
  courseId: string | string[];
}) => {
  return {
    collectOrUncollect: () => {
      const isAdd = courseDetail.value.courseInfoScColor === '#8a8a8a'
      api.post('/mmshoucang/addOrCancelSc', {
        dealType: isAdd ? 'add' : 'cancel',
        type: 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        ord_id: courseId
      }).then(() => {
        courseDetail.value.courseInfoScColor = isAdd ? 'orange' : '#8a8a8a'
        courseDetail.value.courseInfoScCnt += isAdd ? 1 : -1
        Toast.success(`${isAdd ? '' : '取消'}收藏成功`)
      })
    },
    likeOrUnlikeComment: likeOrUnlike,
    likeOrUnlikeCourse: () => likeOrUnlike(courseDetail.value)
  }
}
