/* eslint-disable @typescript-eslint/camelcase */
import { api } from './useAxios'
import { Review, CourseDetail, CourseSeriesDetail, Homework, ActiveColor } from 'momai'
import { Toast } from 'vant'

const showRes = (isAdd: boolean) => {
  //Toast.success(`${isAdd ? '' : '取消'}点赞成功！`)
}

/**
 * 点赞或取消点赞某一个课程、作业、评论的评论
 * @param target 需要取消的评论，课程，作业对象
 */
const likeOrUnlike = (target: Review | CourseDetail | Homework | CourseSeriesDetail) => {
  let isAdd = false
  let type = 0
  const ord_id = target.id
  let nextColor = isAdd ? '1' : '0'
  let nextNumStep = isAdd ? 1 : -1
  const changeNextColorAndNum = (newIsAdd: boolean) => {
    isAdd = newIsAdd
    nextColor = isAdd ? '1' : '0'
    nextNumStep = isAdd ? 1 : -1
  }
  let changeColorAndNum = () => {}
  if ('pjDzColor' in target) {
    changeNextColorAndNum(target.pjDzColor === '0')
    type = 2
    changeColorAndNum = () => {
      target.pjDzColor = nextColor
      target.pjDzCnt += nextNumStep
    }
  } else if ('courseInfoDzColor' in target) {
    type = 0
    changeNextColorAndNum(target.courseInfoDzColor === '0')
    changeColorAndNum = () => {
      target.courseInfoDzColor = nextColor
      target.courseInfoDzCnt += nextNumStep
    }
  } else if ('workDzColor' in target) {
    type = 1
    changeNextColorAndNum(target.workDzCnt === '0')
    changeColorAndNum = () => {
      target.workDzColor = nextColor
      target.workDzCnt += nextNumStep
    }
  }
  api.post('/mmdianzan/addOrCancelDz', {
    ord_id,
    type,
    dealType: isAdd ? 'add' : 'cancel'
  }).then(() => {
    changeColorAndNum()
    showRes(isAdd)
  })
}

export {
  likeOrUnlike,
  showRes
}
