import { computed, Ref } from 'vue'
import { api } from '@/api/useAxios'
import { useLoadList, FetchFunction } from '@/utils/useLoadList'
import { CourseDetail, Review } from 'momai'

export default ({
  reviewPageData,
  courseDetail,
  courseId
}: {
  reviewPageData: {
    current: number;
    size: number;
  };
  courseDetail: Ref<CourseDetail>;
  courseId: string | string[];
}) => {
  const reviews = computed<Review[]>(() => courseDetail.value.evaulIPage.records)
  const total = computed(() => courseDetail.value.evaulIPage.total)
  const fetch: FetchFunction = (pageData, loadEnd) => {
    api.post('/mmpinglun/getPjPageInfo', {
      ordId: courseId,
      pjType: '0',
      ...pageData
    }).then(({ records }) => {
      courseDetail.value.evaulIPage.records.push(...records)
      loadEnd()
    })
  }
  const { loadMore, ...otherLoadProps } = useLoadList({
    pageData: reviewPageData,
    list: reviews,
    total,
    fetch
  })
  return {
    reviews,
    ...otherLoadProps,
    loadMoreReviews: loadMore
  }
}
